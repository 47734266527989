* {
    box-sizing: border-box;
}

body {
    margin: 0px;
    font-family: Inter, sans-serif;
    height: 100vh;
    background-color: #282D35;
    color: #f4f4f4;
}

nav {
    display: flex;
    background-color: #21222A;
    font-family: Inter, sans-serif;
    height: 70px;
    padding: 10px;
}

.nav-tabs {
  /*border-bottom: none !important;*/
  border-color: #65d2f2;
}

.nav-item {
  border: none !important;  /* Optional: removes any border on each tab item */
    border-color: #65d2f2;
}

.nav {
  display: flex; /* Ensures tabs are flex items */
  text-decoration: none;
    border-color: #65d2f2;
}

.nav--logo_text, .nav--title {
    margin: 5;
}

.nav--logo_text {
    margin-right: auto;
    margin-top: 10;
    /* color: #61DAFB;*/
    color : white;
    font-weight: 700;
    font-size: 22px;
}

.nav--title {
    color: #DEEBF8;
    font-weight: 600;
}

.App {
  font-family: Inter, sans-serif;
  text-align: center;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #21222A;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: #61dafb;
  color: #DEEBF8;*/
  background-color: #21222A;
  height: 10px;
  padding: 20px 25px;
  font-family: Inter, sans-serif;
}

.App-link {
  color: #61dafb;
}

/*
.App-header {
    vertical-align: top;
    height: 10%;
}
*/

.App-KeyRates
{
  width: 50%;
  height: 20%;
  display: inline-block;
  vertical-align: top;
  margin: 20px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.main {
  justify-content: center;
  align-items: center;
}

.main--title {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 25px;
    color: white;
    vertical-align: top;
    padding: 10px 100px 10px 10px;
    letter-spacing: -0.05em;
}

.main--sub--title {
    font-weight: 100;
    font-size: 12px;
    color: white;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    padding: 10px 100px 10px 10px;
    letter-spacing: -0.05em;
}

.main--copyright {
    font-weight: 500;
    font-size: 16px;
    color: white;
    vertical-align: top;
    padding: 10px 100px 10px 10px;
    letter-spacing: -0.05em;
}


.main--facts {
    margin-top: 46px;
    max-width: 400px;
}

.main--facts > li {
    line-height: 19px;
    padding-block: 10px;
}

.main--facts > li::marker {
    font-size: 1.4rem;
    color: #61DAFB;
}


.datepicker__title {
  font-weight: 500;
  font-size: 15px;
  color: white;
  background-color: #21222A;
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin: 10 auto;
  padding: 10px;
}

.mailto {

}

a:-webkit-any-link {
    color: #61dafb;
    cursor: pointer;
    text-decoration: underline;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    width: 100px;
}

ul {
  margin-inline-start:10px;
  margin-left: 10px;
}


div.ag-root .ag-cell-focus {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.nav-tabs .nav-link
{
 color: #2c7a92;
 background-color: transparent;
 border-color: transparent;
 font-size: 15px;
 text-decoration: none !important;
}

.nav-tabs .nav-link.active {
 background-color: transparent; /* Transparent background for the active tab */ /* Bootstrap primary color text */
 color: #65d2f2;
 border-bottom: 0px solid #61dafb;
 border-radius: 0;
 border-color: transparent;
 font-weight: 500;
}

.nav-link:hover {
color: #61dafb; /*will change color of text within the element on hover */
background-color: transparent;
border-radius: 0;
border-spacing: 0;
}

.nav-link:disabled {
color: grey; /*will change color of text within the element on hover */
}

.accordion {
  --bs-accordion-bg:#282D35;
  --bs-accordion-btn-padding-y: 5px;
  --bs-accordion-border-radius: 0px;
  --bs-accordion-inner-border-radius: 0px;
  --bs-accordion-active-bg:#21222a;
  --bs-accordion-border-color: #3b3b3b;
  --bs-accordion-btn-color: white;
  --bs-accordion-active-color:  white;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0;
  --bs-accordion-body-padding-x: 0.0rem;
  --bs-accordion-body-padding-y: 0.0rem;
}

.container-fluid
{
  --bs-gutter-x: 5;
}
